<template>
  <div class="ha-bill-edit">
    <Header class="noprint" appName="Report Details"></Header>

    <v-card class="noprint mx-auto pa-5 justify-space-around" style="/*min-width: 70rem; width: 70rem*/"
      v-if="reportObj && reportObj.patient">
      <div class="text-left mb-4">
        <v-btn small outlined dark color="primary" class="ml-5" v-if="mode == 'display'"
          @click="printReport()">Print</v-btn>
        <v-btn small outlined dark color="primary" class="ml-5" v-if="mode == 'display'"
          @click="showEmailDialog()">Email</v-btn>
        <v-btn small outlined dark color="primary" class="ml-5" v-if="mode == 'display'"
          @click="viewPDF()">ViewPDF</v-btn>
          
      </div>
      <v-divider></v-divider>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <div class="ha-label">Patient:</div>
              <div class="ha-value">
                {{ reportObj.patient.firstName }}
                {{ reportObj.patient.lastName }}
              </div>
            </v-row>
            <v-row>
              <div class="ha-label">Referred By:</div>
              <div class="ha-value">
                <span v-if="reportObj.doctor">{{ reportObj.doctor.title }}
                  {{ reportObj.doctor.firstName }}
                  {{ reportObj.doctor.lastName }}</span>
                <span v-else>Self</span>
              </div>
            </v-row>
            <v-row>
              <div class="ha-label">Sex / Age:</div>
              <div class="ha-value">
                {{ reportObj.patient.gender }} / {{ computeAge(age) }}
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" sm="5">
            <v-row class="justify-sm-start">
              <div class="ha-label">Sampling Date:</div>
              <div class="ha-value">
                {{
                  new Date(reportObj.reportDate.seconds * 1000).toLocaleString(
                    "en-IN"
                  )
                }}
              </div>
            </v-row>
            <v-row class="justify-sm-start">
              <div class="ha-label">Report Date:</div>
              <div class="ha-value">
                {{
                  new Date(
                    reportObj.lastUpdatedOn.seconds * 1000
                  ).toLocaleString("en-IN")
                }}
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row no-gutters class="ha-table-heading" v-show="!showCKEditor && !isMobile">
          <v-col v-for="(item, index) in settings.print.report.columns" :key="index + '-screen'" :cols="item.flex">
            <div v-if="item.id == 'name'">Test Name</div>
            <div v-if="item.id == 'result'">Result</div>
            <div v-if="item.id == 'refRange'">Ref Range</div>
            <div v-if="item.id == 'unit'">Unit</div>
          </v-col>
        </v-row>
        <hr v-show="!showCKEditor && !isMobile" />
        <!-- Level1 -->
        <div v-show="showCKEditor">
          <div class="text-h6">{{ CKTest.name }}</div>
          <div class="MyCKEditor"></div>
          <v-card-actions class="justify-center">
            <v-btn tile dark color="primary" @click="backFromCK(true)" v-if="mode == 'edit'">Ok</v-btn>
            <v-btn text @click="backFromCK(false)" v-if="mode == 'edit'">Cancel</v-btn>
            <v-btn tile dark color="primary" @click="backFromCK(false)" v-if="mode != 'edit'">Back</v-btn>
          </v-card-actions>
        </div>
        <div v-for="l1 in reportObj.testItems" :key="l1.testId" v-show="!showCKEditor">
          <TestRow :test="l1" level="1" :mode="mode" v-if="l1.type != 'DESC'"
            :columns="settings.print.report.columns" />
          <div v-else>
            <v-row class="justify-space-between">
              <div>{{ l1.name }}</div>
              <v-btn class="mr-16" text @click="displayCKEditor(l1)">Editor</v-btn>
            </v-row>
          </div>

          <!-- Level2 -->
          <div v-for="l2 in l1.childTests" :key="l2.testId">
            <TestRow :test="l2" level="2" :mode="mode" v-if="l2.type != 'DESC'"
              :columns="settings.print.report.columns" />
            <div v-else>
              <v-row>
                <v-col>{{ l2.name }}</v-col>
                <v-col>
                  <v-btn text @click="showCKEditor = true">Editor</v-btn>
                </v-col>
              </v-row>
            </div>
            <!-- Level3 -->
            <div v-for="l3 in l2.childTests" :key="l3.testId">
              <TestRow :test="l3" level="3" :mode="mode" v-if="l3.type != 'DESC'"
                :columns="settings.print.report.columns" />
              <div v-else>
                <v-row>
                  <v-col>{{ l3.name }}</v-col>
                  <v-col>
                    <v-btn text @click="showCKEditor = true">Editor</v-btn>
                  </v-col>
                </v-row>
              </div>
              <!-- Level4 -->
              <div v-for="l4 in l3.childTests" :key="l4.testId">
                <TestRow :test="l4" :peers="l3.childTests" level="4" :mode="mode" v-if="l4.type != 'DESC'"
                  :columns="settings.print.report.columns" :shortcuts="settings.shortcuts" />
                <div v-else>
                  <v-row class="justify-space-between no-gutters" align="center">
                    <div>{{ l4.name }}</div>
                    <v-btn class="mr-16" text @click="displayCKEditor(l4)">Editor</v-btn>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="print" v-if="reportObj && reportObj.patient">
			<div
				v-for="(printPage, index) in printPages"
				:key="printPage.testId"
				v-bind:class="{
					'px1-16': true,
					'ha-page-break': index == printPages.length - 1 ? false : true,
				}"
				v-bind:style="{
					fontSize: settings.print.report.fontSize + 'rem',
					paddingTop:
						(((customPrintFlag == false &&
							settings.print.report.headerEmailOnly != true) ||
							(customPrintFlag == true && showHeader == true)) &&
							settings.print.report.headerType == 'IMAGE') == true
							? '0rem'
							: settings.print.report.headerSpace + 'rem',
					position: 'relative',
					height: 'calc(100vh)',
				}"
			>
				<div
					v-if="
						(settings.print.report.headerEmailOnly != true ||
							(customPrintFlag == true && showHeader == true)) &&
						settings.print.report.headerType == 'TEXT'
					"
					class="justify-center text-center pb-10"
				>
					<v-row class="text-h5 justify-center">{{
						settings.print.report.headerText1
					}}</v-row>
					<v-row class="subtitle-2 justify-center">{{
						settings.print.report.headerText2
					}}</v-row>
				</div>
				<v-row
					no-gutters
					v-if="
						((customPrintFlag == false &&
							settings.print.report.headerEmailOnly != true) ||
							(customPrintFlag == true && showHeader == true)) &&
						settings.print.report.headerType == 'IMAGE'
					"
					class="pb-4"
				>
					<img
						style="width: 100%; height: 8rem"
						:src="settings.print.report.headerImgURL"
						alt="Report Header Image"
					/>
				</v-row>

				<div class="px-16">
					<v-card-text class="pa-0" style="font-size: inherit">
						<v-row no-gutters>
							<v-col cols="5">
								<v-row no-gutters>
									<div class="ha-label" style="width: 4.5rem">Name:</div>
									<div class="ha-value">
										{{ reportObj.patient.title }} {{ reportObj.patient.firstName }}
										{{ reportObj.patient.lastName }}
									</div>
								</v-row>
								<v-row no-gutters>
									<div class="ha-label" style="width: 4.5rem">Sex / Age:</div>
									<div class="ha-value">
										{{ reportObj.patient.gender }} / {{ computeAge(age) }}
									</div>
								</v-row>
								<v-row no-gutters>
									<div class="ha-label" style="width: 4.5rem">Ref. by:</div>
									<div class="ha-value">
										<span v-if="reportObj.doctor"
											>{{ !reportObj.doctor.isOrg ? "Dr." : "" }}
											{{ reportObj.doctor.firstName }}
											{{ reportObj.doctor.lastName }}</span
										>
										<span v-else>Self</span>
									</div>
								</v-row>
							</v-col>

							<v-col cols="5">
								<v-row class="justify-sm-start justify-md-center" no-gutters>
									<div class="ha-label" style="width: 6rem">Sampling Date:</div>
									<div class="ha-value">
										<!-- new Date(reportObj.reportDate.seconds * 1000).toLocaleString("en-IN") -->
										{{
											getDisplayDateFromTimestamp(
												reportObj.reportDate.seconds * 1000,
												true
											)
										}}
									</div>
								</v-row>
								<v-row class="justify-sm-start justify-md-center" no-gutters>
									<div class="ha-label" style="width: 6rem">Report Date:</div>
									<div class="ha-value">
										{{
											getDisplayDateFromTimestamp(
												reportObj.lastUpdatedOn.seconds * 1000,
												true
											)
										}}
									</div>
								</v-row>
								<v-row class="justify-sm-start justify-md-center" no-gutters>
									<div class="ha-label" style="width: 6rem">Report ID:</div>
									<div class="ha-value">
										{{ reportObj.reportId }}
									</div>
								</v-row>
							</v-col>
							<v-col cols="2">
								<v-row no-gutters class="justify-end">
									<div v-bind:id="'qrcode' + index" class="text-end"></div>
								</v-row>
								<v-row no-gutters class="justify-end">
									<div class="text-end" style="font-size: 0.6rem">Online Access</div>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>

					<hr class="mt-1" />
					<h3 class="pa-5 pt-1 text-center">TEST REPORT</h3>

					<v-card-text class="pa-0" style="font-size: inherit">
						<v-row
							no-gutters
							class="ha-table-heading"
							v-if="
								printPage != undefined &&
								printPage[0] != undefined &&
								printPage[0].type != 'DESC'
							"
						>
							<v-col
								v-for="(item, index) in settings.print.report.columns"
								:key="index + '-screen'"
								:cols="item.flex"
							>
								<div v-if="item.id == 'name'">Test Name</div>
								<div v-if="item.id == 'result'">Result</div>
								<div v-if="item.id == 'refRange'">Ref Range</div>
								<div v-if="item.id == 'unit'">Unit</div>
							</v-col>
						</v-row>

						<hr />

						<!-- Level1 -->
						<div v-for="l1 in printPage" :key="l1.testId">
							<TestRow
								:test="l1"
								level="1"
								:mode="mode"
								v-if="l1.type != 'DESC'"
								:columns="settings.print.report.columns"
								:linespace="settings.print.report.lineSpace"
							/>
							<div v-else>
								<div class="ha-fos">{{ l1.name }}</div>
								<span v-html="l1.resultTemplate"></span>
							</div>

							<!-- Level2 -->
							<div
								v-for="l2 in l1.childTests"
								:key="l2.testId"
								:columns="settings.print.report.columns"
							>
								<TestRow
									:test="l2"
									level="2"
									:mode="mode"
									v-if="l2.type != 'DESC'"
									:columns="settings.print.report.columns"
									:linespace="settings.print.report.lineSpace"
								/>
								<!-- Level3 -->
								<div v-for="l3 in l2.childTests" :key="l3.testId">
									<TestRow
										:test="l3"
										level="3"
										:mode="mode"
										v-if="l3.type != 'DESC'"
										:columns="settings.print.report.columns"
										:linespace="settings.print.report.lineSpace"
									/>
									<!-- Level4 -->
									<div v-for="l4 in l3.childTests" :key="l4.testId">
										<TestRow
											:test="l4"
											level="4"
											:mode="mode"
											v-if="l4.type != 'DESC'"
											:columns="settings.print.report.columns"
											:linespace="settings.print.report.lineSpace"
										/>
									</div>
								</div>
							</div>
						</div>
					</v-card-text>

					<div v-if="reportObj.impression" class="my-4">
						<h4>Impression:</h4>
						{{ reportObj.impression }}
					</div>

					<div style="font-size: 0.8rem; text-align: center">
						~ page {{ index + 1 }} of {{ printPages.length }} ~
					</div>
					<div
						v-if="index == printPages.length - 1"
						style="font-size: 0.8rem; text-align: center"
					>
						~~ End of Report ~~
					</div>

					<v-row
						no-gutters
						class="ha-footer"
						v-bind:style="{
							position:
								((customPrintFlag == true && showSignatureOnlyLastPage == true) ||
									settings.print.report.signOnlyLastPage == true) &&
								index == printPages.length - 1
									? 'absolute'
									: 'fixed',
							bottom: settings.print.report.footerSpace + 'rem',
						}"
						align="end"
						v-if="
							(customPrintFlag == false && !settings.print.report.signOnlyLastPage) ||
							(customPrintFlag == false &&
								settings.print.report.signOnlyLastPage == true &&
								index == printPages.length - 1) ||
							(customPrintFlag == true &&
								showSignature == true &&
								showSignatureOnlyLastPage == false) ||
							(customPrintFlag == true &&
								showSignatureOnlyLastPage == true &&
								index == printPages.length - 1)
						"
					>
						<v-col
							v-for="(signature, signatureIndex) in settings.print.report.signatureArr"
							:key="signatureIndex"
						>
							<v-row no-gutters class="justify-center">
								<img
									v-if="signature.imgURL"
									:src="signature.imgURL"
									alt="Image not found."
									height="50px"
								/>
							</v-row>
							<v-row no-gutters class="justify-center">{{ signature.name }}</v-row>
							<v-row no-gutters class="justify-center">{{
								signature.designation
							}}</v-row>
						</v-col>
					</v-row>
				</div>
				<div
					v-if="
						(settings.print.report.footerEmailOnly != true ||
							(customPrintFlag == true && showHeader == true)) &&
						settings.print.report.footerType == 'TEXT'
					"
					class="justify-center text-center pb-10"
				>
					<v-row class="justify-center ha-footer-banner">{{
						settings.print.report.footerText
					}}</v-row>
				</div>
				<v-row
					no-gutters
					v-if="
						((customPrintFlag == false &&
							settings.print.report.footerEmailOnly != true) ||
							(customPrintFlag == true && showHeader == true)) &&
						settings.print.report.footerType == 'IMAGE'
					"
					class="pb-4"
				>
					<img
						class="ha-footer-img"
						style="width: 100%; z-index: -0"
						:src="settings.print.report.footerImgURL"
						alt="Report Footer Image"
					/>
				</v-row>
				<div class="watermark" v-if="orgObj.isTrial == true">
					Health Amaze Demo Account
				</div>
			</div>
		</div>
    <!-- OTP -->
    <v-dialog v-model="OTPDialog" max-width="800">
      <v-card>
        <v-card-title class="headline">Enter OTP</v-card-title>

        <v-otp-input length="4" v-model="OTPValue" class="mx-4"></v-otp-input>

        <v-alert dense outlined type="error" v-show="OTPErrorMsg">
          Incorrect OTP.
        </v-alert>
        <v-card-actions>
          <v-btn dark color="primary" @click="validateOTP()"> Ok </v-btn>
          <v-btn text @click="
            OTPDialog = false;
          OTPErrorMsg = false;
          ">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- email patient -->
    <v-dialog v-model="emailDialog" max-width="800" v-if="reportObj && reportObj.patient">
      <v-card>
        <v-card-title class="headline">Email Report</v-card-title>
        <v-card-text>This lab report will be emailed</v-card-text>
        <v-form ref="form" class="mx-2" id="emailForm">
          <v-text-field class="ml-2" v-model="emailId" label="E-mail" type="email" outlined required dense :rules="[
            rules.required,
            (v) => /.+@gmail\.com+/.test(v) || 'Must end with @gmail.com',
          ]"></v-text-field>
        </v-form>
        <v-card-actions>
          <v-btn dark color="primary" @click="sendEmail()"> Send Email </v-btn>
          <v-btn text @click="emailDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" bottom timeout="2000">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Busy dialog -->
    <v-dialog v-model="busyDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please wait...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { db } from "@/db";
import Header from "@/components/Header.vue";
// import { orgSrv } from "@/services/Org";
import { db } from "@/db";
import TestRow from "@/components/TestRow.vue";
import { UtilSrv } from "../services/Util";

export default {
  name: "PatientReport",
  components: {
    Header,
    TestRow,
  },
  mounted() {
    let qrcodeScript = document.createElement("script");
    qrcodeScript.setAttribute("src", "/qrcode.min.js");
    document.head.appendChild(qrcodeScript);

    if (!window.CKEDITOR_TRANSLATIONS) {
      let ckeditorScript = document.createElement("script");
      ckeditorScript.setAttribute("src", "/ckeditor5/build/ckeditor.js");
      document.head.appendChild(ckeditorScript);
    }
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
      mode: "display",
      orgRef: {},
      reportRef: {},
      //   valid: false,
      snackbar: false,
      snackbarText: "",
      customPrintFlag: false,
      //   deleteDialog: false,
      titleArr: [
        { text: "Mr.", value: "Mr." },
        { text: "Mrs.", value: "Mrs." },
        { text: "Ms.", value: "Ms." },
        { text: "Master", value: "Master" },
        { text: "Miss", value: "Miss" },
        { text: "Baby", value: "Baby" },
        { text: "Baby Of", value: "Baby Of" },
        { text: "Dr.", value: "Dr." },
        { text: " ", value: " " },
      ],
      statusArr: [
        { text: "Initial", value: "INITIAL" },
        { text: "In Process", value: "INPROGRESS" },
        { text: "Completed", value: "COMPLETED" },
        { text: "Printed", value: "PRINTED" },
        { text: "Delivered", value: "DELIVERED" },
      ],
      isMobile: window.isMobile(),
      //   isDisabled: false,
      searchName: "",
      reportObjBackup: {},
      reportObj: {
        title: "Mr.",
        firstName: "",
        lastName: "",
        gender: "Male",
        dob: new Date().toJSONLocal().substring(0, 10),
        email: "",
        address: "",
        phone: "",
        billid: "",
        bloodgroup: "BloodGroup",
      },
      //   viewObj: {},
      dobDateCreate: 1,
      dobMonthCreate: "1",
      dobYearCreate: "",
      currYear: new Date().getFullYear(),
      disableEmail: false,
      printPages: [],
      age: 0,

      CKEditorRef: null,
      showCKEditor: false,
      CKTest: {},

      // custom print
      customPringDialog: false,
      showSignature: true,

      OTPDialog: false,
      OTPErrorMsg: "",
      OTPValue: "",

      emailMode: false,
      emailDialog: false,
      emailId: "",

      busyDialog: false,
      orgObj: {
        isTrial: false
      }
    };
  },
  firestore: {},
  created: function () {
    this.computeAge = UtilSrv.computeAge;
    this.init();
  },
  methods: {
    init() {
      this.orgRef = db.collection("Org").doc(this.$route.params.orgId);
      window.ha.log(this.$route.params.orgId);
      window.ha.log(this.$route.params.reportId);
      window.ha.log(this.orgRef);

      this.initSettings();

      this.emailMode = this.$route.params.emailMode;

      // if (this.emailMode == "true") {
      return this.loadReport();
      // }
      // this.loadReport();
      // var sendOTP = window.firebase
      //   .functions()
      //   .httpsCallable("default-patientOTP");
      // sendOTP({
      //   orgId: this.$route.params.orgId,
      //   billId: this.$route.params.reportId,
      // })
      //   .then((result) => {
      //     if (result.data) {
      //       window.ha.log("OTP: " + result.data);
      //       this.OTPFromServer = result.data;
      //       this.OTPDialog = true;
      //     }
      //   })
      //   .catch((err) => {
      //     window.ha.error(err);
      //   });
    },
    validateOTP() {
      if (btoa(this.OTPValue) == this.OTPFromServer) {
        this.OTPErrorMsg = false;
        this.OTPDialog = false;
        this.loadReport();
      } else {
        this.OTPErrorMsg = true;
      }
    },
    loadReport() {
      // retrieve a document
      this.orgRef
        .collection("LabReport")
        .doc(this.$route.params.reportId)
        .get()
        .then((snapshot) => {
          this.reportObj = snapshot.data();
          this.reportRef = snapshot.ref;

          window.ha.log(this.reportObj);

          this.dob = new Date(this.reportObj.patient.dob.seconds * 1000);
          this.dobDateCreate = this.dob.getDate();
          this.dobMonthCreate = this.dob.getMonth() + 1;
          this.dobYearCreate = this.dob.getFullYear();

          this.patientAge();

          if (this.emailMode == "true") this.print();
        });
    },
    updateCKEditMode: function () {
      if (!this.CKEditorRef) return;

      if (this.mode == "edit") this.CKEditorRef.isReadOnly = false;
      else this.CKEditorRef.isReadOnly = true;
    },
    displayCKEditor: function (selectedTest) {
      this.CKTest = selectedTest;
      this.showCKEditor = true;
      if (this.CKEditorRef != null) {
        this.updateCKEditMode();
        this.CKEditorRef.setData(this.CKTest.resultTemplate);
        return;
      } else {
        setTimeout(() => {
          // initialize ckeditor
          let editor = document.querySelector(".MyCKEditor");
          window.ha.log("Setting CKEditor");
          window.ClassicEditor.create(editor, {
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote",
              "indent",
              "outdent",
              "undo",
              "redo",
              "insertTable",
              "alignment",
            ],
            heading: {
              options: [
                {
                  model: "paragraph",
                  title: "Paragraph",
                  class: "ck-heading_paragraph",
                },
                {
                  model: "heading1",
                  view: "h1",
                  title: "Heading 1",
                  class: "ck-heading_heading1",
                },
                {
                  model: "heading2",
                  view: "h2",
                  title: "Heading 2",
                  class: "ck-heading_heading2",
                },
              ],
            },
            table: {
              contentToolbar: [
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "tableProperties",
                "tableCellProperties",
              ],
            },
          })
            .then((createdEditor) => {
              this.CKEditorRef = createdEditor;
              this.CKEditorRef.setData(this.CKTest.resultTemplate);
              this.updateCKEditMode();
            })
            .catch((error) => {
              window.ha.error(error);
            });
        }, 100);
      }
    },
    backFromCK: function (shouldSave) {
      if (shouldSave == true) {
        this.CKTest.resultTemplate = this.CKEditorRef.getData();
      }
      this.showCKEditor = false;
    },
    initSettings: function () {
      this.orgRef
        .collection("Settings")
        .doc("SETTINGS")
        .get()
        .then((snapshot) => {
          this.settings = snapshot.data();
          this.settings.print.report.headerEmailOnly = false;
          console.log(this.settings);
        });
    },
    showToast: function (msgText) {
      this.snackbarText = msgText;
      this.snackbar = true;
    },
    printReport() {
      this.print(true);
    },
    print: function (forcePrint) {
      // cleanup qrcode
      let i = 0;
      while (document.getElementById("qrcode" + i)) {
        document.getElementById("qrcode" + i).innerHTML = "";
        ++i;
      }

      window.ha.log(this.reportObj.testItems);
      this.prepareForPrint();

      setTimeout(
        function () {
          let i = 0;
          while (document.getElementById("qrcode" + i)) {
            new window.QRCode(document.getElementById("qrcode" + i), {
              text:
                "patient.healthamaze.app/#/patientReport/org/" +
                this.$route.params.orgId +
                "/report/" +
                this.reportObj.id,
              width: 70,
              height: 70,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: window.QRCode.CorrectLevel.H,
            });
            ++i;
          }
          setTimeout(
            function () {
              if (this.emailMode != "true" || forcePrint == true)
                window.print();
            }.bind(this),
            500
          );
        }.bind(this),
        500
      );
    },
    prepareForPrint: function () {
      let tests = this.reportObj.testItems;

      let limit = this.settings.print.report.linesPerPage || 10;
      let pages = [];
      let page = [];
      let available = limit;
      let descTestArr = [];

      function copyAttributes(from) {
        let to = {}; // block level
        to.name = from.name;
        to.note = from.note;
        to.refRanges = from.refRanges;
        to.result = from.result;
        to.abnormal = from.abnormal;
        to.unit = from.unit;
        to.type = from.type;
        to.childTests = [];

        return to;
      }

      // level1
      for (let t1 of tests) {
        if (t1.type == "NORMAL" && !t1.result) continue;

        if (t1.type == "DESC") {
          descTestArr.push(t1);
          continue;
        }

        let bl1 = copyAttributes(t1); // block level 1

        if (
          available <= 0 ||
          this.settings.print.report.pageBreakOnFOS == true
        ) {
          pages.push(page);
          page = [];
          available = limit;
        }

        // add block to page
        page.push(bl1);
        available = this.reduceAvailable(bl1, available);
        // --available;

        if (t1.type == "NORMAL") {
          continue;
        }

        // level2
        for (let t2 of t1.childTests) {
          if (t2.type == "NORMAL" && !t2.result) continue;

          if (t2.type == "DESC") {
            descTestArr.push(t2);
            continue;
          }

          let bl2 = copyAttributes(t2); // block level 2

          if (available <= 0) {
            pages.push(page);
            page = [];
            available = limit;

            // initialize block 1 and 2
            bl1 = copyAttributes(t1); // block level 1
            page.push(bl1);
            available = this.reduceAvailable(bl1, available);
            // --available;
          }

          bl1.childTests.push(bl2);
          available = this.reduceAvailable(bl2, available);
          // --available;

          if (t2.type == "NORMAL") {
            continue;
          }

          // level3
          for (let t3 of t2.childTests) {
            if (t3.type == "NORMAL" && !t3.result) continue;

            if (t3.type == "DESC") {
              descTestArr.push(t3);
              continue;
            }

            let bl3 = copyAttributes(t3); // block level 2

            if (available <= 0) {
              pages.push(page);
              page = [];
              available = limit;

              // initialize block 1 and 2
              bl1 = copyAttributes(t1); // block level 1
              page.push(bl1);
              available = this.reduceAvailable(bl1, available);
              // --available;

              bl2 = copyAttributes(t2); // block level 1
              bl1.childTests.push(bl2);
              available = this.reduceAvailable(bl2, available);
              // --available;
            }

            bl2.childTests.push(bl3);
            available = this.reduceAvailable(bl3, available);
            // --available;

            if (t3.type == "NORMAL") {
              continue;
            }

            // level4
            for (let t4 of t3.childTests) {
              if (t4.type == "NORMAL" && !t4.result) continue;

              if (t4.type == "DESC") {
                descTestArr.push(t4);
                continue;
              }

              let bl4 = copyAttributes(t4); // block level 2

              if (available <= 0) {
                pages.push(page);
                page = [];
                available = limit;

                // initialize block 1 and 2 and 3
                bl1 = copyAttributes(t1); // block level 1
                page.push(bl1);
                available = this.reduceAvailable(bl1, available);
                // --available;

                bl2 = copyAttributes(t2); // block level 1
                bl1.childTests.push(bl2);
                available = this.reduceAvailable(bl2, available);
                // --available;

                bl3 = copyAttributes(t3); // block level 1
                bl2.childTests.push(bl3);
                available = this.reduceAvailable(bl3, available);
                // --available;
              }

              bl3.childTests.push(bl4);
              available = this.reduceAvailable(bl4, available);
              // --available;

              if (t4.type == "NORMAL") {
                continue;
              }
            }
          }
        }
      }
      if (page.length > 0) {
        pages.push(page);
      }
      // recursively clean up groups and fos with no childTests
      pages = this.recursiveCleanup(pages);
      for (let descTest of descTestArr) {
        pages.push([descTest]);
      }
      window.ha.log(pages);
      this.printPages = pages;
    },
    reduceAvailable(t, available) {
      window.ha.log("Reducing for " + t.name + " Available: " + available);
      // check if more than 1 ref range and if none are highlighted, then count it and reduce
      if (t.refRanges && t.refRanges.length > 0) {
        let highlightedRefRange = null;
        for (let refRange of t.refRanges) {
          if (refRange.highlight == true) {
            highlightedRefRange = refRange;
            break;
          }
        }

        if (!highlightedRefRange) {
          // available -= t.refRanges.length;
          for (let refRange of t.refRanges) {
            available -= this.reduceRefRangeHelper(refRange);
          }
        } else {
          available -= this.reduceRefRangeHelper(highlightedRefRange);
        }
      } else {
        --available;
      }

      if (t.method != undefined && t.method != null && t.method != "") {
        --available;
      }
      if (t.note != undefined && t.note != null && t.note != "") {
        let noteLines = Math.ceil(t.note.length / 120);
        available -= noteLines;
      }
      return available;
    },
    reduceRefRangeHelper(refRange) {
      let printableRefRange =
        (refRange.refName != undefined ? refRange.refName : "") +
        (refRange.min != undefined ? refRange.min : "") +
        (refRange.max != undefined ? refRange.max : "");

      return Math.ceil(printableRefRange.length / 25);
    },
    recursiveCleanup(pages) {
      for (let page of pages) {
        page = this.recursiveCleanupHelper(page);
      }
      return pages;
    },
    recursiveCleanupHelper(blkArr) {
      for (let index = 0; index < blkArr.length; ++index) {
        let blk = blkArr[index];
        if (
          blk.type == "FOS" &&
          (blk.childTests == undefined || blk.childTests.length == 0)
        ) {
          blkArr.splice(index, 1);
          index--;
        } else if (
          blk.type == "GROUP" &&
          (blk.childTests == undefined || blk.childTests.length == 0)
        ) {
          blkArr.splice(index, 1);
          index--;
        } else if (blk.type == "GROUP" || blk.type == "FOS") {
          blk.childTests = this.recursiveCleanupHelper(blk.childTests);
          if (blk.childTests == undefined || blk.childTests.length == 0) {
            blkArr.splice(index, 1);
            index--;
          }
        }
      }
      return blkArr;
    },
    patientAge() {
      this.age = this.dob;
      // var today = new Date();
      // if (
      //   this.dobYearCreate > 1900 &&
      //   this.dobYearCreate <= today.getFullYear()
      // ) {
      //   var age = today.getFullYear() - this.dob.getFullYear();
      //   var m = today.getMonth() - this.dob.getMonth();
      //   if (m < 0 || (m === 0 && today.getDate() < this.dob.getDate())) {
      //     age = age - 1;
      //   }

      //   this.age = age;
      // }
    },
    getDisplayDateFromTimestamp(timestamp, showTime) {
      return window.getDisplayDateFromTimestamp(timestamp, showTime);
    },

    showEmailDialog() {
      this.emailDialog = true;
    },

    viewPDF() {
      this.busyDialog = true;
      var generatePDF = window.firebase
          .functions()
          .httpsCallable("default-generatePDF");
      generatePDF({
          orgId: this.$route.params.orgId,
          reportId: this.$route.params.reportId,
      })
      .then((result) => {
          // Decode the Base64 string back into byte data
          const base64String = result.data.pdf;
          const byteCharacters = atob(base64String);  // Decode Base64
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          // Create a Blob from the byte array
          const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });

          // Create a link element to open the PDF in a new tab
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.open(pdfUrl, '_blank');

          console.log("PDF opened in a new tab");
          this.busyDialog = false;
      })
      .catch((error) => {
          console.error("Error generating PDF:", error);
      });
    },

    sendEmail() {
      if (!document.getElementById("emailForm").checkValidity()) {
        document.getElementById("emailForm").reportValidity();
        return;
      }
      this.busyDialog = true;
      var sendEmail = window.firebase
        .functions()
        .httpsCallable("default-sendPatientEmail");
      sendEmail({
        orgId: this.$route.params.orgId,
        reportId: this.$route.params.reportId,
        emailId: this.emailId,
      })
        .then((result) => {
          window.ha.log(result);
          this.emailDialog = false;
          this.busyDialog = false;
          this.showToast("Email Sent successfully.");
        })
        .catch((err) => {
          window.ha.error(err);
          this.busyDialog = false;
        });
    },
  },
};
</script>

<style scoped>
.ha-bill-edit {
  background: #007bff1f;
  height: 100%;
  min-height: 100vh;
}

.theme--light.v-select .v-select__selection--disabled {
  color: black !important;
}

.ha-label {
  font-weight: 100;
  font-size: 1rem;
  color: black;
}

.ha-value {
  font-weight: 500;
  font-size: 1rem;
  color: black;
  margin-left: 0.5rem;
}

.ha-table-heading {
  font-weight: 500;
  color: black;
}

@media print {
  .v-application .secondary {
    background: white !important;
  }

  .noprint {
    display: none;
  }

  .ha-bill-edit {
    background: white;
  }

  .print {
    font-family: sans-serif;
    display: block !important;
    font-size: 0.7em;
  }

  .ha-label,
  .ha-value {
    font-size: inherit;
  }

  .ha-value {
    font-weight: bold;
  }

  .ha-table-heading {
    font-weight: bold;
    color: black;
  }

  @page {
    margin: 0;
  }

  .watermark {
    display: inline-block !important;
    font-size: 2rem;
    position: fixed;
    bottom: 50%;
    left: 25%;
    color: #00000075;
    opacity: 0.5;
    pointer-events: none;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
  }

  .ha-footer {
    width: 100%;
    left: 0;
  }

  .ha-footer-banner {
    position: fixed;
    bottom: 5rem;
    text-align: center;
    width: 100%;
  }

  .ha-footer-img {
    position: fixed;
    bottom: 0rem;
    text-align: center;
    width: 100%;
  }
}

.print {
  display: none;
}

.ha-page-break {
  page-break-after: always;
}

.watermark {
  display: none;
}
</style>
