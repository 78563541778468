<template>
  <div>
    <Header class="noprint" appName="Select the Report"></Header>
    <!-- list of reports -->
    <div class="mx-auto mt-5 text-center">
      <!-- <h3 style="color: #283593">SELECT THE LAB</h3>
      <br /> -->
      <div
        v-for="(item, index) in reportArr"
        :key="index"
        style="width: 80vw"
        class="mx-auto"
      >
        <v-list-item
          three-line
          style="background: white; border-bottom: 1px solid silver"
          @click="fetchReportDetails(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ item.patient.title }} {{ item.patient.firstName }}
              {{ item.patient.lastName }}</v-list-item-title
            >
            <v-list-item-subtitle>
              {{ item.reportDateDisplay }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.tests }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <div class="ha-tile" @click="loadReports(item.id)">
          <div>{{ item.name }}</div>
          <div>{{ item.address }}</div>
        </div>
        <div class="ha-tile">
          <div>{{ item.name }}</div>
          <div>{{ item.address }}</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";
import Header from "@/components/Header.vue";
export default {
  name: "Home",
  props: {},
  components: {
    Header,
  },
  data() {
    return {
      phone: "",
      reportArr: [],
    };
  },
  created() {
    this.phone = window.localStorage.getItem("phone");
    if (!this.phone) {
      this.$router.push("Home");
    }
    this.fetchReports();
  },
  methods: {
    fetchReports() {
      this.reportArr = [];
      db.collection("Org/" + this.$route.params.lab + "/LabReport")
        .orderBy("reportDate", "desc")
        .get()
        .then((snapshotArr) => {
          if (snapshotArr && snapshotArr.docs) {
            for (let report of snapshotArr.docs) {
              let obj = report.data();
              console.log(obj);
              obj.reportDateDisplay = new Date(
                obj.reportDate.seconds * 1000
              ).toLocaleDateString();
              obj.tests = "";
              for (let fos of obj.testItems) {
                for (let test of fos.childTests) {
                  obj.tests += " , " + test.name;
                }
              }
              obj.tests = obj.tests.substr(2);
              this.reportArr.push(obj);
            }
          }
        });
    },
    loadReports(labId) {
      this.$router.push("ReportList", {
        labId: labId,
      });
    },
    fetchReportDetails(reportId) {
      console.log(this.$route.params.lab);
      this.$router.push({
        name: "PatientReportEmail",
        params: {
          orgId: this.$route.params.lab,
          reportId: reportId,
          emailMode: "true",
        },
      });
    },
  },
};
</script>

<style>
#app {
  background: #2196f3;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ha-tile {
  background: white;
  color: black;
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px !important;
  text-align: center;
  transition: transform 0.2s;
}
.ha-tile:hover {
  /* background: white;
  transform: scale(1.05);
  box-shadow: #cfcfcf 1px 1px 10px; */
  border-color: #f4f4f4 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 0.167s linear, box-shadow 0.167s linear;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
