<template>
  <!-- <div>
    <nav class="navbar navbar-dark bg-primary text-light">
      <div class="material-icons home-icon" v-on:click="navToHome">home</div>
      <div>{{appName}}</div>
      <div>
        <button type="button" class="btn btn-primary dropdown-toggle material-icons ha-header-menu" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
          
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <button class="dropdown-item" type="button">Menu TO-DO</button>
        </div>
      </div>
    </nav>
	</div>-->

  <div>
    <v-toolbar color="primary" dark class="elevation-0" no-gutters>
      <!-- <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" depressed dark v-bind="attrs" v-on="on">
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item @click="navToHome()" v-if="isMobile">
              <v-list-item-icon>
                <v-img src="/png/014-house.png" max-width="2rem"></v-img>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-for="(item, index) in apps"
              :key="index"
              @click="navToApp(item)"
            >
              <v-list-item-icon>
                <v-img :src="item.icon" max-width="2rem"></v-img>
              </v-list-item-icon>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->
      <v-toolbar-title style="width: 100%">
        <div class="d-flex justify-space-between">
          <div v-if="!isMobile">
            <span style="position: fixed">
              <span class="ha-logo">Health Amaze </span>
              <span style="font-family: 'Roboto'; font-size: 0.8em">
                {{ orgObj.name }}</span
              >
            </span>
          </div>
          <h1 style="font-weight: normal; font-size: 1.25rem">{{ appName }}</h1>
          <div>&nbsp;</div>
        </div>
      </v-toolbar-title>
      <!-- <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>more_horiz</v-icon>
          </v-btn>
        </template>
        
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img :src="user.photoURL" alt="User Image" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ user.displayName }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              <v-list-item-subtitle v-if="orgObj">
                {{ orgObj.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="navToOrganisation()">
            <v-list-item-icon>
              <v-img src="/png/016-company.png" max-width="2rem"></v-img>
            </v-list-item-icon>
            <v-list-item-title>My Organizations</v-list-item-title>
          </v-list-item>

          <v-list-item @click="settings">
            <v-list-item-avatar>
              <div class="material-icons mr-4">settings</div>
            </v-list-item-avatar>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="whatsappSupport">
            <v-list-item-avatar>
              <div class="material-icons mr-4">chat</div>
            </v-list-item-avatar>
            <v-list-item-title>WhatsApp Support</v-list-item-title>
          </v-list-item>
          <v-list-item @click="signOut">
            <div class="material-icons mr-8">logout</div>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
      </v-menu> -->
    </v-toolbar>
  </div>
</template>

<script>
// import { orgSrv } from "@/services/Org";
// import { appsSrv } from "@/services/Apps";

export default {
  name: "Header",
  data() {
    return {
      drawer: null,
      user: {},
      //   apps: appsSrv.getAppsList(),
      isMobile: window.isMobile(),
      orgObj: {
        name: "",
      },
    };
  },
  props: {
    appName: String,
  },
  created() {
    // window.app.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     // User is signed in.
    //     // window.ha.log(user);
    //     this.user = window.app.auth().currentUser;
    //     window.signInPromise
    //       .then(orgSrv.getOrg)
    //       .then(orgSrv.getOrgDetails)
    //       .then(this.initOrgInfo);
    //   } else {
    //     // No user is signed in.
    //     window.ha.log("Logged out.");
    //   }
    // });
  },
  methods: {
    // initOrgInfo(orgObj) {
    //   this.orgObj = orgObj;
    //   if (this.orgObj == undefined) {
    //     window.ha.log("Org not found. Hence signup");
    //     this.$router.push({ name: "Signup" });
    //   }
    //   window.ha.log("Check expiry");
    //   orgSrv.checkExpiry(this.orgObj, this.$route, this.$router);
    // },
    // navToHome() {
    //   this.$router.push({ name: "Home" });
    // },
    // navToApp(app) {
    //   window.ha.log(app);
    //   this.$router.push(app.route);
    // },
    // navToOrganisation() {
    //   this.$router.push({ name: "Organisation" });
    // },
    // whatsappSupport() {
    //   window.open("https://wa.me/+919740698449", "blank");
    // },
    // signOut() {
    //   window.app
    //     .auth()
    //     .signOut()
    //     .then(
    //       () => {
    //         // Sign-out successful.
    //         window.ha.log("Logged out successfully");
    //         window.location = "/";
    //       },
    //       function (error) {
    //         // An error happened.
    //         window.ha.log("Error Occurred while signout", error);
    //       }
    //     );
    // },
    // settings: function () {
    //   this.$router.push({ name: "Settings" });
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home-icon {
  font-size: 1.6rem;
  cursor: pointer;
}
.ha-nav-app {
  background: white;
}
.ha-nav-app:hover {
  border-color: #f4f4f4 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 0.167s linear, box-shadow 0.167s linear;
  cursor: pointer;
}
.ha-logo {
  cursor: pointer;
  /* font-family: "Kaushan Script", cursive; */
  font-family: "Comfortaa", cursive;
}
</style>
