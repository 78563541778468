<template>
  <div>
    <Header class="noprint" appName="Select the Lab"></Header>
    <!-- list of reports -->
    <div class="mx-auto mt-5 text-center">
      <!-- <h3 style="color: #283593">SELECT THE LAB</h3>
      <br /> -->
      <div style="width: 80vw" class="mx-auto">
        <!-- <div
          v-for="(item, index) in orgArr"
          :key="index"
          class="ha-tile"
          @click="loadReports(item.id)"
        >
          <div>{{ item.name }}</div>
          <div>{{ item.address }}</div>
        </div> -->


        <v-data-table
          :headers="headers"
          :items="orgArr"
          class="elevation-1"
          @click:row="loadReports"
          hide-default-header
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Select the Lab
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <span>{{ item.name }}</span>
          </template>
          <template v-slot:item.address="{ item }">
            <span>{{ item.address }}</span>
          </template>
        </v-data-table>
        </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/db";
import Header from "@/components/Header.vue";
export default {
  name: "Home",
  props: {},
  components: {
    Header,
  },
  data() {
    return {
      phone: "",
      orgArr: [],
      headers: [
            {
              "text": "Name",
              "align": "start",
              "sortable": false,
              "value": "name"
            },
            {
              "text": "Address",
              "align": "start",
              "sortable": false,
              "value": "address"
            }
          ]
    };
  },
  created() {
    this.phone = window.localStorage.getItem("phone");
    if (!this.phone) {
      this.$router.push("Home");
    }
    this.fetchLabs();
  },
  methods: {
    fetchLabs() {
      this.orgArr = [];
      db.collection("PhoneDirectory")
        .where("phoneNumber", "==", this.phone)
        .get()
        .then((snapshotArr) => {
          if (
            snapshotArr &&
            snapshotArr.docs &&
            snapshotArr.docs[0] &&
            snapshotArr.docs[0].exists
          ) {
            let user = snapshotArr.docs[0].data();
            let orgs = user.orgs;
            db.collection("Org")
              .where("id", "in", orgs)
              .get()
              .then((snapshotArr) => {
                for (let doc of snapshotArr.docs) {
                  let org = doc.data();
                  console.log(org);
                  this.orgArr.push({
                    id: org.id,
                    name: org.name,
                    address: org.address,
                  });
                  window.localStorage.setItem(
                    "skipOTPForOrgs",
                    JSON.stringify(this.orgArr)
                  );
                }
                this.OTPDialog = false;
              });
          }
        });
    },
    loadReports(lab) {
      this.$router.push({
        name: "ReportList",
        params: {
          lab: lab.id,
        },
      });
    },
  },
};
</script>

<style>
#app {
  background: #2196f3;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ha-tile {
  background: white;
  color: black;
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px !important;
  text-align: center;
  transition: transform 0.2s;
  max-width: 30rem;
  min-width: 10rem;
}
.ha-tile:hover {
  /* background: white;
  transform: scale(1.05);
  box-shadow: #cfcfcf 1px 1px 10px; */
  border-color: #f4f4f4 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 0.167s linear, box-shadow 0.167s linear;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
