import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css"; // Add this line

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import colors from "vuetify/lib/util/colors";

// window.ha.log(colors.red.darken1);

Vue.use(Vuetify);
const opts = {
	theme: {
		// dark: true,
		themes: {
			light: {
				primary: colors.blue.darken3, // #E53935
				secondary: colors.blue.lighten5, // #FFCDD2
				accent: colors.lime.base, // #3F51B5,
			},

			dark: {
				primary: colors.blue.darken3, // #E53935
				secondary: colors.blue.lighten5, // #FFCDD2
				accent: colors.lime.base, // #3F51B5,
			},
		},
	},
	options: {
		customProperties: true,
	},
	icons: {
		iconfont: "md",
	},
};

export default new Vuetify(opts);
